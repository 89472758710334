import React from "react"

import Layout from "../components/layout"
import { graphql, useStaticQuery } from "gatsby"
import SEO from "../components/seo"

import Button from "../components/button"
import MainBanner from "../components/main-banner"
import ConceptSection from "../components/concept-section"

import DinosaurIcon from "../assets/icons/icons-1x/dinosaur.svg"
import IdeaIcon from "../assets/icons/icons-1x/idea.svg"
import PlanetIcon from "../assets/icons/icons-1x/planet.svg"
import ExperienciasMeiquerLogo from "../assets/logos/logo-experiencias-meiquer.svg"

const ExperienciasMeiquerPage = props => {
  const data = useStaticQuery(graphql`
    query ExperienciasMeiquerQuery {
      meiquerPascua2Img: file(relativePath: { eq: "meiquer-pascua-02.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      meiquerPascuaImg: file(relativePath: { eq: "meiquer-pascua-01.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const mainBannerContent = (
    <>
      <img
        style={{ width: "7em" }}
        src={ExperienciasMeiquerLogo}
        alt="Experiencias Meiquer Logo"
      />
      <p>
        Cursos vacacionales de{" "}
        <span className="c-turquoise w-bold">juego creativo</span> para niñas y
        niños con actividades <span className="w-bold">inspiradoras</span> para
        imaginar, crear y compartir en el Tecnológico de Monterrey
      </p>
      <div className="columns">
        <div className="column is-two-thirds pl-0">
          <Button>
            <span>Suscribirme</span>
          </Button>
        </div>
      </div>
    </>
  )

  const reasons = [
    {
      top: (
        <img style={{ height: "10rem" }} src={PlanetIcon} alt="Ícono Planeta" />
      ),
      content: (
        <>
          <h3>Ambiente seguro</h3>
          <p>
            Nuestros cursos son realizados en las instalaciones del Tecnológico
            de Monterrey, Campus Guadalajara.
          </p>
        </>
      ),
    },
    {
      top: <img style={{ height: "10rem" }} src={IdeaIcon} alt="Ícono Idea" />,
      content: (
        <>
          <h3>Aprende y crece</h3>
          <p>
            Retos para jugar y desarrollar habilidades con Ciencia, Tecnología,
            Matemáticas, Ingeniería y Arte.
          </p>
        </>
      ),
    },
    {
      top: (
        <img
          style={{ height: "10rem" }}
          src={DinosaurIcon}
          alt="Ícono Dinosaurio"
        />
      ),
      content: (
        <>
          <h3>Experiencia única</h3>
          <p>
            Experiencia única Las niñas y los niños viven una experiencia
            creativa jugando con alumnos universitarios.
          </p>
        </>
      ),
    },
  ]

  const reasonsTitle = (
    <h2 className="w-normal text-right">
      ¿Cómo es la <span className="c-turquoise w-bold">experiencia</span> para{" "}
      <span className="w-bold">niñas y niños</span>?
    </h2>
  )

  const infoBannerContent = (
    <>
      <h2 className="w-normal">
        <span className="c-turquoise w-bold">Juega</span> y{" "}
        <span className="w-bold">crea</span> en un{" "}
        <span className="s-italic">laboratorio creativo</span>
      </h2>
      <p>
        Nuestros cursos son <span className="w-bold">experiencias</span> que{" "}
        <span className="w-bold">inspiran</span> a las niñas y niños a jugar,
        imaginar y crear en un laboratorio para aprender experimentando.
      </p>
      <div className="columns">
        <div className="column is-two-thirds pl-0">
          <Button>
            <span>Ver Fotos</span>
          </Button>
        </div>
      </div>
    </>
  )

  const services = [
    {
      top: (
        <>
          <img
            style={{ width: "6em" }}
            src={ExperienciasMeiquerLogo}
            alt="Experiencias Meiquer Logo"
          />
          <p style={{ letterSpacing: ".3rem" }} className="c-turquoise w-bold">
            Pascua
          </p>
        </>
      ),
      content: (
        <>
          <p>1 semana de cursos durante pascua.</p>
          <p className="c-turquoise">
            <span className="w-bold">$1,200</span> por semana
          </p>
        </>
      ),
      bottom: <Button disabled>Próximamente</Button>,
    },
    {
      top: (
        <>
          <img
            style={{ width: "6em" }}
            src={ExperienciasMeiquerLogo}
            alt="Experiencias Meiquer Logo"
          />
          <p style={{ letterSpacing: ".3rem" }} className="c-turquoise w-bold">
            Verano
          </p>
        </>
      ),
      content: (
        <>
          <p>1 semana de cursos durante verano.</p>
          <p className="c-turquoise">
            <span className="w-bold">$1,200</span> por semana
          </p>
        </>
      ),
      bottom: <Button disabled>Próximamente</Button>,
    },
    {
      top: (
        <>
          <img
            style={{ width: "6em" }}
            src={ExperienciasMeiquerLogo}
            alt="Experiencias Meiquer Logo"
          />
          <p style={{ letterSpacing: ".3rem" }} className="c-turquoise w-bold">
            Verano +
          </p>
        </>
      ),
      content: (
        <>
          <p>2 semanas de cursos durante verano.</p>
          <p className="c-turquoise">
            <span className="w-bold">$1,000</span> por semana
          </p>
        </>
      ),
      bottom: <Button disabled>Próximamente</Button>,
    },
    {
      top: (
        <>
          <img
            style={{ width: "6em" }}
            src={ExperienciasMeiquerLogo}
            alt="Experiencias Meiquer Logo"
          />
          <p style={{ letterSpacing: ".3rem" }} className="c-turquoise w-bold">
            Day Pass
          </p>
        </>
      ),
      content: (
        <>
          <p>1 día de actividades en cualquier temporada.</p>
          <p className="c-turquoise">
            <span className="w-bold">$350</span> por día
          </p>
        </>
      ),
      bottom: <Button disabled>Próximamente</Button>,
    },
  ]

  const servicesTitle = (
    <h2 className="w-normal">
      ¿Quieres <span className="c-turquoise w-bold">jugar</span> y{" "}
      <span className="s-italic">vivir</span> una{" "}
      <span className="w-bold">experiencia nueva</span>?
    </h2>
  )

  return (
    <Layout location={props.location}>
      <SEO title="Experiencias Meiquer" />
      <MainBanner
        image={data.meiquerPascua2Img.childImageSharp.fluid}
        content={mainBannerContent}
      />
      <ConceptSection concepts={reasons} title={reasonsTitle} />
      <MainBanner
        image={data.meiquerPascuaImg.childImageSharp.fluid}
        content={infoBannerContent}
        fromBottom={false}
      />
      <ConceptSection concepts={services} title={servicesTitle} />
    </Layout>
  )
}

export default ExperienciasMeiquerPage
